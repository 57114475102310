var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, inline: "" } },
        [
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "-",
                  "value-format": "yyyy-MM-dd",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "150px" },
                  attrs: { placeholder: "请选择活动", clearable: "" },
                  model: {
                    value: _vm.form.promote_info_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "promote_info_id", $$v)
                    },
                    expression: "form.promote_info_id",
                  },
                },
                _vm._l(_vm.activeList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.info_name, value: item.info_id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "150px" },
                  attrs: { placeholder: "请选择业务员", clearable: "" },
                  model: {
                    value: _vm.form.staff_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "staff_id", $$v)
                    },
                    expression: "form.staff_id",
                  },
                },
                _vm._l(_vm.staffList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "150px" },
                  attrs: { placeholder: "请选择状态", clearable: "" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                _vm._l(_vm.statusList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("goods-search", {
                ref: "goodsSearch",
                attrs: {
                  "is-table": false,
                  "popper-class": "select-idx selectDC",
                },
                on: { "select-goods-all": _vm.selectGoodsId },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("client-search", {
                ref: "clientSearch",
                attrs: { "popper-class": "select-idx selectDC" },
                on: { "select-id": _vm.selectId },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                attrs: { placeholder: "备注" },
                model: {
                  value: _vm.form.info,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "info", $$v)
                  },
                  expression: "form.info",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-checkbox",
                {
                  attrs: { "true-label": "1", "false-label": "0" },
                  model: {
                    value: _vm.form.is_merge,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_merge", $$v)
                    },
                    expression: "form.is_merge",
                  },
                },
                [_vm._v(" 合并存货与赠品 ")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function () {
                      _vm.form.pageSize = 10
                      _vm.form.pageNo = 1
                      _vm.fetchData()
                    },
                  },
                },
                [_vm._v(" 查询 ")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-button", { on: { click: _vm.clearForm } }, [
                _vm._v("清空"),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-button", { on: { click: _vm.handleExport } }, [
                _vm._v("导出"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { stripe: "", data: _vm.list },
        },
        [
          _c(
            "el-table-column",
            { attrs: { align: "center", type: "index", width: "50" } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        "popper-class": "custom-table-checkbox",
                        trigger: "hover",
                      },
                    },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.checkList,
                            callback: function ($$v) {
                              _vm.checkList = $$v
                            },
                            expression: "checkList",
                          },
                        },
                        _vm._l(_vm.columns, function (item, index) {
                          return _c("el-checkbox", {
                            key: index,
                            attrs: { label: item.label },
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { slot: "reference", type: "text" },
                          slot: "reference",
                        },
                        [
                          _c("vab-remix-icon", {
                            attrs: { icon: "settings-line" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._l(_vm.finallyColumns, function (item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                prop: item.prop,
                width: "",
                label: item.label,
                align: "center",
              },
            })
          }),
        ],
        2
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.form.pageNo,
          layout: _vm.layout,
          "page-size": _vm.form.pageSize,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }