<template>
  <!-- NAME[epic=动销] 预存货汇总统计 -->
  <div class="orderTest-container">
    <el-form ref="form" :model="form" inline>
      <el-form-item>
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.promote_info_id"
          placeholder="请选择活动"
          clearable
          style="width: 150px"
        >
          <el-option
            v-for="(item, index) in activeList"
            :key="index"
            :label="item.info_name"
            :value="item.info_id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.staff_id"
          placeholder="请选择业务员"
          clearable
          style="width: 150px"
        >
          <el-option
            v-for="(item, index) in staffList"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.status"
          placeholder="请选择状态"
          clearable
          style="width: 150px"
        >
          <el-option
            v-for="(item, index) in statusList"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <goods-search
          ref="goodsSearch"
          :is-table="false"
          :popper-class="'select-idx selectDC'"
          @select-goods-all="selectGoodsId"
        ></goods-search>
      </el-form-item>
      <el-form-item>
        <client-search
          ref="clientSearch"
          :popper-class="'select-idx selectDC'"
          @select-id="selectId"
        ></client-search>
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.info" placeholder="备注"></el-input>
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="form.is_merge" true-label="1" false-label="0">
          合并存货与赠品
        </el-checkbox>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="
            () => {
              form.pageSize = 10
              form.pageNo = 1
              fetchData()
            }
          "
        >
          查询
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="clearForm">清空</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleExport">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" stripe :data="list">
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in finallyColumns"
        :key="index"
        :prop="item.prop"
        width=""
        :label="item.label"
        align="center"
      ></el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { preAllDetail, activeSelectList } from '@/api/createPreInventory'
  import { getStaffList } from '@/api/setPrice'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import ClientSearch from '@/baseComponents/clientSearch'
  import { downloadFile } from '@/api/Employee'
  export default {
    name: 'PreAllOrder',
    components: {
      GoodsSearch,
      ClientSearch,
    },
    data() {
      return {
        loading: true,
        list: [],
        activeList: [], //活动下拉
        staffList: [], //业务员下拉
        total: 0,
        // 状态 1：未结清，2：已结清，3：已终止
        statusList: [
          {
            label: '未结清',
            value: '1',
          },
          {
            label: '已结清',
            value: '2',
          },
          {
            label: '已终止',
            value: '3',
          },
        ],
        layout: 'total, sizes, prev, pager, next, jumper',
        time: '',
        form: {
          start_time: '',
          end_time: '',
          status: '',
          promote_info_id: '', //预存货活动id，0：业务员创建
          staff_id: '', //业务员ID
          goods_id: '', //商品ID
          cust_id: '', //客户id
          info: '', //备注
          pageNo: 1, //当前页
          pageSize: 10, //每页条数
          is_merge: '0', //合并存货与赠品 是否合并存货与赠品,0：不是，1：是
          sort: '', //排序字段
          order: '', //排序顺序
        },
        checkList: [
          '客户名称',
          '单号',
          '活动名称',
          '签单日期',
          '业务员',
          '存货/赠品',
          '存货数量',
          '累计还货',
          '余货数量',
        ],

        columns: [
          {
            order: 1,
            label: '客户名称',
            prop: 'cust_name',
            width: '180',
          },
          {
            order: 2,
            label: '单号',
            prop: 'bill_code',
            width: '180',
          },
          {
            order: 3,
            label: '活动名称',
            prop: 'activity_name',
            width: '180',
          },
          {
            order: 4,
            label: '签单日期',
            prop: 'create_at',
            width: '180',
          },
          {
            order: 5,
            label: '业务员',
            prop: 'staff_name',
            width: '180',
          },
          {
            order: 6,
            label: '存货/赠品',
            prop: 'goods_name',
            width: '120',
          },
          {
            order: 7,
            label: '存货数量',
            prop: 'goods_num',
            width: '180',
          },
          {
            order: 8,
            label: '累计还货',
            prop: 'goods_use_num',
            width: '180',
          },
          {
            order: 9,
            label: '余货数量',
            prop: 'goods_left_num',
            width: '180',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let arr = []
        this.checkList.forEach((item) => {
          arr.push(this.columns.filter((item2) => item2.label == item)[0])
        })
        return _.sortBy(arr, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      this.fetchData()
      // 活动下拉
      activeSelectList().then((res) => {
        if (res.code == 200) {
          this.activeList = res.data
        }
      })
      // 业务员下拉
      getStaffList().then((res) => {
        if (res.code == 200) {
          this.staffList = res.data
        }
      })
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, msg, code, totalCount } = await preAllDetail(this.form)
        console.log(data)
        this.list = data.rows
        this.total = totalCount
        this.loading = false
      },
      // clientChoose(val) {
      //   this.form.cust_id = val
      // },
      selectId(val) {
        console.log('selectId')
        console.log(val)
        if (val.cust_id) {
          this.form.cust_id = val
        } else {
          this.form.cust_id = val
        }
      },
      selectGoodsId(val) {
        this.form.goods_id = val.goods_id
      },
      clearForm() {
        this.time = ''
        this.form = {
          start_time: '',
          end_time: '',
          status: '',
          promote_info_id: '', //预存货活动id，0：业务员创建
          staff_id: '', //业务员ID
          goods_id: '', //商品ID
          cust_id: '', //客户id
          info: '', //备注
          pageNo: 1, //当前页
          pageSize: 10, //每页条数
          sort: '', //排序字段
          order: '', //排序顺序
        }
        this.$refs.goodsSearch.resetForm()
        this.$refs.clientSearch.resetForm()
      },
      // 分页
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      handleExport() {
        downloadFile(
          '/promoteAdmin/goods-order/total-export',
          '预存货汇总统计.xlsx',
          this.form
        )
      },
    },
  }
</script>
<style lang="scss" scoped></style>
